@import url("https://rsms.me/inter/inter.css");

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: #e0e0e0;
  font-family: "Inter", sans-serif;
}

a {
  pointer-events: all;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  margin-left: 14px;
}

a,
a:visited {
  color: black;
}

.nav {
  position: absolute;
  right: 50px;
  top: 50px;
}

.stat {
  position: absolute;
  left: 50px;
  top: 50px;
}
